



































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { BASE_URL } from '~/const/base-url'

const API_TABLE_DATA = '/api_producer_payout/get_producer_payout_info/'
const API_PAY_PRODUCER = '/api_producer_payout/pay_producer'
const API_PAY_ALL_PRODUCERS = '/api_producer_payout/pay_all_producers/'
const API_TOTAL_PAYED_ON_MONTH = '/api_producer_payout/total_payed_on_month'

@Component
export default class Payouts extends Vue {
    showModalPay = false
    showModalPayAll = false
    showModalPdfAccountant = false
    tableData: any[] = []
    selectedData: any = null
    yearForPdf = new Date().getFullYear()
    monthForPdf = 1
    totalPayedOnMonth = 0
    linkForCSVPayPal = `${BASE_URL}/api_producer_payout/export_csv_for_paypal`

    @Watch('yearForPdf')
    yearForPdfChanged() {
        this.getTotalPayedOnMonth()
    }

    @Watch('monthForPdf', { immediate: true })
    monthForPdfChanged() {
        this.getTotalPayedOnMonth()
    }

    async loadTableData() {
        try {
            const response = await Vue.$axios.get(API_TABLE_DATA)
            this.tableData = response.data.producer_payout_info.sort((a: any, b: any) => b.amount_due - a.amount_due)
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    showModalSinglePayment(index: number) {
        this.selectedData = this.tableData[index]
        this.showModalPay = true
    }

    async payProducer(producerId: number) {
        try {
            await Vue.$axios.post(`${API_PAY_PRODUCER}/${producerId}/`)
            this.loadTableData()
        } catch (err) {
            console.error(err)
        } finally {
            this.closeAllModals()
        }
    }

    async payAllProducers() {
        try {
            await Vue.$axios.post(API_PAY_ALL_PRODUCERS)
            this.loadTableData()
        } catch (err) {
            console.error(err)
        } finally {
            this.closeAllModals()
        }
    }

    closeAllModals() {
        this.showModalPay = false
        this.showModalPayAll = false
        this.showModalPdfAccountant = false
    }

    async getTotalPayedOnMonth() {
        const response = await Vue.$axios.get(`${API_TOTAL_PAYED_ON_MONTH}/${this.yearForPdf}/${this.monthForPdf}/`)
        this.totalPayedOnMonth = response.data
    }

    exportPdfForCommercialista() {
        window.open(`${BASE_URL}/pdf/generate_pdf_of_payed_payouts/${this.yearForPdf}/${this.monthForPdf}/`, null)
    }

    mounted() {
        this.loadTableData()
    }
}
